import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BookMeeting from "./BookMeeting";

const intervalTime = 4000; // Time in milliseconds to rotate words

const HeroSection = () => {
  const { t } = useTranslation();
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isExiting, setIsExiting] = useState(false);
  const [isStopped, setIsStopped] = useState(false);

  const rotatingWords = t("rotatingWords", { returnObjects: true });

  useEffect(() => {
    if (isStopped) return;

    const interval = setInterval(() => {
      setIsExiting(true);
      setTimeout(() => {
        setCurrentWordIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % rotatingWords.length;
          if (nextIndex === 0) {
            setIsStopped(true);
          }
          return nextIndex;
        });
        setIsExiting(false);
      }, 1000); // Match with CSS transition duration
    }, intervalTime);

    return () => clearInterval(interval);
  }, [isStopped, rotatingWords.length]);

  return (
    <div className="hero-image-container">
      <div className="hero-content container h-100">
        <div className="row h-100 align-items-center">
         
            <h1 className="mb-4 display-3 font-weight-bold herotext">
             Norsk Datateknikk har etter 6 år lagt ned
            </h1>
          </div>
         
      </div>
    </div>
  );
};

export default HeroSection;
